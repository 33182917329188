
const voilationsState = {
    voilationsData: [],
    isLoading: false
}

export const voilationReducer = (state = voilationsState, action) => {
    switch (action.type) {
        case "GET_VOILATIONS_LIST":
            return {
                ...state,
                voilationsData: [...action.voilationsList],
                isLoading: false
            };
        case "LOADING_VOILATION_LIST":
            return {
                ...state,
                isLoading: true,
            };
        case "VOILATION_LIST_ERROR":
            return {
                ...state,
                isLoading: false,
            }
        default:
            return state;
    }
}