import { combineReducers } from "redux";
import config from './templateConfig/';
import Navigation from './navigation/';
import todoReducer from "./todo/";
import Notes from "./note/";
import chatReducer from "./chat/";
import emailReducer from "./email/";
import Icons from "./icons/";
import { authReducer } from "./auth/auth";
import { parkingLogsReducer } from "./parkingLogs/parkingLogs";
import { voilationReducer } from "./voilations/voilations";
import { locationReducer } from "./locations/locations";
import { userReducer } from "./users/user";

const rootReducer = combineReducers({
  config,
  Navigation,
  todoApp: todoReducer,
  noteApp: Notes,
  chatApp: chatReducer,
  emailApp: emailReducer,
  Icons,
  authReducer,
  parkingLogs:parkingLogsReducer,
  voilationReducer,
  locationReducer,
  userReducer
});

export default rootReducer;