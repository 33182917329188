const usersInitialState = {
    userData: [],
    singleUser: {},
    isLoading: false
}

export const userReducer = (state = usersInitialState, action) => {
    switch (action.type) {
        case "GET_USERS_LIST":
            return {
                ...state,
                userData: [...action.usersList],
                singleUser : {},
                isLoading: false
            }
        case "GET_USER":
            return {
                ...state,
                singleUser: action.user
            }
        case "CLEAR_USER":
            return {
                ...state,
                singleUser: {}
            }
        default:
            return state;
    }
}