const initialState = {
    isAuthenticated: false,
    isLoading: false,
    authErrorMessgae: ''
};
export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                isLoading:false,
                isAuthenticated:action.isAuthenticated,
                authErrorMessgae:''
            }
        case 'LOGIN_FAILED':
            return {
                ...state,
                isLoading:false,
                isAuthenticated: false,
                authErrorMessgae: action.error
            }
        case 'AUTH_REQUEST':
            return {
                ...state,
                isLoading:true,
            }
        case 'CLEAR_AUTH_MESSAGE':
            return {
                ...state,
                authErrorMessgae:''
            }
        default:
            return state;
    }
}