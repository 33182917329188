
const initialParkingLogState = {
    parkingLogsData: {
        data: [],
        total: 0,
        totalPages: 0,
        perPage:0
    },
    isLoading: false,
    logsError: ""
}
export const parkingLogsReducer = (state = initialParkingLogState, action) => {
    switch (action.type) {
        case "GET_PARKING_LOGS":
            return {
                ...state,
                isLoading: false,
                parkingLogsData: {
                    data: [...action.logsData],
                    total: action.total,
                    totalPages: action.totalPages
                }
            }
        case "LOADING_PARKING_LOGS":
            return {
                ...state,
                isLoading: true
            }
        default:
            return {
                ...state
            };
    }
}