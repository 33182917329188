const locationInitialState = {
    locationData: [],
    isLoading: false
}

export const locationReducer = (state = locationInitialState, action) => {
    switch (action.type) {
        case "GET_LOCATIONS_LIST":
            return {
                ...state,
                locationData: [...action.locationsList],
                isLoading: false,
            };
        case "LOADING_LOCATION_LIST":
            return {
                ...state,
                isLoading: true
            }
        case "LOCATION_LIST_ERROR":
            return {
                ...state,
                isLoading: false
            }
        default:
            return state;
    }
}